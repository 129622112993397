import React, { Fragment } from 'react';

import { Row, Col, Divider } from '@components/Grid';
import Checkboxes from '@components/Checkboxes';
import Alert from '@components/Alert';
import Input from '@components/Input';
import Select from '@components/Select';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Button from '../../components/Button';

const Address = ({ addresses, onContinue, onPrevious, countries }) => {
    return (
        <Formik
            validationSchema={Yup.object().shape({
                shipping: Yup.object().shape({
                    name: Yup.string().required(`Full name is required`),
                    email: Yup.string()
                        .email(`Must be an email address`)
                        .required(`Email is required`),
                    phoneNumber: Yup.string().required(`Phone Number is required`),
                    country: Yup.string().required('Country is required'),
                    city: Yup.string().required('City is required'),
                    state: Yup.string().required('State / Province is required'),
                    zip: Yup.string().required('ZIP Code / Postal Code is required'),
                    addressOne: Yup.string().required('Address is required'),
                    addressTwo: Yup.string().nullable(),
                }),
                billing: Yup.object().shape({
                    sameAsShipping: Yup.boolean().required(),
                    name: Yup.string().when('sameAsShipping', {
                        is: true, // alternatively: (val) => val == true
                        then: Yup.string().nullable().notRequired(),
                        otherwise: Yup.string().required(`Full name is required`),
                    }),
                    email: Yup.string().when('sameAsShipping', {
                        is: true, // alternatively: (val) => val == true
                        then: Yup.string().nullable().notRequired(),
                        otherwise: Yup.string()
                            .email(`Must be an email address`)
                            .required(`Email is required`),
                    }),
                    phoneNumber: Yup.string().when('sameAsShipping', {
                        is: true, // alternatively: (val) => val == true
                        then: Yup.string().nullable().notRequired(),
                        otherwise: Yup.string().required(`Phone Number is required`),
                    }),
                    country: Yup.string().when('sameAsShipping', {
                        is: true, // alternatively: (val) => val == true
                        then: Yup.string().nullable().notRequired(),
                        otherwise: Yup.string().required('Country is required'),
                    }),
                    city: Yup.string().when('sameAsShipping', {
                        is: true, // alternatively: (val) => val == true
                        then: Yup.string().nullable().notRequired(),
                        otherwise: Yup.string().required('City is required'),
                    }),
                    state: Yup.string().when('sameAsShipping', {
                        is: true, // alternatively: (val) => val == true
                        then: Yup.string().nullable().notRequired(),
                        otherwise: Yup.string().required('State is required'),
                    }),
                    zip: Yup.string().when('sameAsShipping', {
                        is: true, // alternatively: (val) => val == true
                        then: Yup.string().nullable().notRequired(),
                        otherwise: Yup.string().required('ZIP Code is required'),
                    }),
                    addressOne: Yup.string().when('sameAsShipping', {
                        is: true, // alternatively: (val) => val == true
                        then: Yup.string().nullable().notRequired(),
                        otherwise: Yup.string().required('Address is required'),
                    }),
                    addressTwo: Yup.string().nullable(),
                }),
            })}
            initialValues={{
                shipping: {
                    name: addresses?.shipping?.name,
                    email: addresses?.shipping?.email,
                    phoneNumber: addresses?.shipping?.phoneNumber,
                    country: addresses?.shipping?.country,
                    city: addresses?.shipping?.city,
                    state: addresses?.shipping?.state,
                    zip: addresses?.shipping?.zip,
                    addressOne: addresses?.shipping?.addressOne,
                    addressTwo: addresses?.shipping?.addressTwo,
                },
                billing: {
                    sameAsShipping: addresses?.billing?.sameAsShipping ?? true,
                    name: addresses?.billing?.name,
                    email: addresses?.billing?.email,
                    phoneNumber: addresses?.billing?.phoneNumber,
                    country: addresses?.billing?.country,
                    city: addresses?.billing?.city,
                    state: addresses?.billing?.state,
                    zip: addresses?.billing?.zip,
                    addressOne: addresses?.billing?.addressOne,
                    addressTwo: addresses?.billing?.addressTwo,
                },
            }}
            onSubmit={(values, actions) => onContinue({ values, actions })}
        >
            {(props) => {
                const { values, setFieldValue, handleSubmit, isSubmitting } = props;

                const handleBillingSameAsShippingChange = () => {
                    setFieldValue('billing.sameAsShipping', !values.billing.sameAsShipping);
                    setFieldValue('billing.name', undefined);
                    setFieldValue('billing.email', undefined);
                    setFieldValue('billing.phoneNumber', undefined);
                    setFieldValue('billing.country', undefined);
                    setFieldValue('billing.state', undefined);
                    setFieldValue('billing.zip', undefined);
                    setFieldValue('billing.addressOne', undefined);
                    setFieldValue('billing.addressTwo', null);
                };

                return (
                    <Fragment>
                        <h1>Address</h1>
                        <p>Fill in your shipping and billing details</p>
                        <Divider />

                        <h2>Shipping details</h2>
                        <Details type="shipping" countries={countries} {...props} />
                        <Divider margin={4} />

                        <h2>Billing details</h2>
                        <Checkboxes onChange={handleBillingSameAsShippingChange}>
                            <Checkboxes.Checkbox
                                label="Use shipping address for my billing address"
                                checked={values.billing.sameAsShipping}
                            />
                        </Checkboxes>

                        <Divider margin={3} />

                        {!values.billing.sameAsShipping && (
                            <Fragment>
                                <Details type="billing" countries={countries} {...props} />
                                <Divider margin={4} />
                            </Fragment>
                        )}

                        <Row between="xs">
                            <Col>
                                <Button text="Back" secondary onClick={onPrevious} />
                            </Col>
                            <Col>
                                <Button
                                    text="Next"
                                    onClick={handleSubmit}
                                    submitting={isSubmitting}
                                />
                            </Col>
                        </Row>
                    </Fragment>
                );
            }}
        </Formik>
    );
};

const Details = ({ touched, errors, values, handleChange, type, countries }) => {
    const typeTouched = touched[type];
    const typeErrored = errors[type];

    return (
        <Fragment>
            <Input
                label="Full name"
                name={`${type}.name`}
                value={values[type].name}
                onChange={handleChange}
            />
            {typeTouched && typeErrored && touched[type].name && errors[type].name && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert message={errors[type].name} type="error" />
                </Fragment>
            )}
            <Divider margin={2} />

            <Input
                label="Email address"
                name={`${type}.email`}
                value={values[type].email}
                onChange={handleChange}
            />
            {typeTouched && typeErrored && touched[type].email && errors[type].email && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert message={errors[type].email} type="error" />
                </Fragment>
            )}
            <Divider margin={2} />

            <Input
                label="Phone Number"
                name={`${type}.phoneNumber`}
                value={values[type].phoneNumber}
                onChange={handleChange}
            />
            {typeTouched && typeErrored && touched[type].phoneNumber && errors[type].phoneNumber && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert message={errors[type].phoneNumber} type="error" />
                </Fragment>
            )}
            <Divider margin={2} />

            <Select
                label="Country"
                name={`${type}.country`}
                onChange={handleChange}
                value={values[type].country}
            >
                {countries.map((country) => (
                    <Select.Option key={country.code} label={country.name} value={country.code} />
                ))}
            </Select>
            {typeTouched && touched[type].name && typeErrored && errors[type].country && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert message={errors[type].country} type="error" />
                </Fragment>
            )}
            <Divider margin={2} />

            <Row>
                <Col xs={12} md={4}>
                    <Input
                        label="City"
                        name={`${type}.city`}
                        value={values[type].city}
                        onChange={handleChange}
                    />
                    {typeTouched && typeErrored && touched[type].city && errors[type].city && (
                        <Fragment>
                            <Divider margin={2} />
                            <Alert message={errors[type].city} type="error" />
                        </Fragment>
                    )}
                    <Divider margin={2} />
                </Col>

                <Col xs={12} md={4}>
                    <Input
                        label="State / Province"
                        name={`${type}.state`}
                        value={values[type].state}
                        onChange={handleChange}
                    />
                    {typeTouched && typeErrored && touched[type].state && errors[type].state && (
                        <Fragment>
                            <Divider margin={2} />
                            <Alert message={errors[type].state} type="error" />
                        </Fragment>
                    )}
                    <Divider margin={2} />
                </Col>

                <Col xs={12} md={4}>
                    <Input
                        label="ZIP Code / Postal Code"
                        name={`${type}.zip`}
                        value={values[type].zip}
                        onChange={handleChange}
                    />
                    {typeTouched && typeErrored && touched[type].zip && errors[type].zip && (
                        <Fragment>
                            <Divider margin={2} />
                            <Alert message={errors[type].zip} type="error" />
                        </Fragment>
                    )}
                    <Divider margin={2} />
                </Col>
            </Row>

            <Input
                label="Address one"
                name={`${type}.addressOne`}
                value={values[type].addressOne}
                onChange={handleChange}
            />
            {typeTouched && typeErrored && touched[type].addressOne && errors[type].addressOne && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert message={errors[type].addressOne} type="error" />
                </Fragment>
            )}
            <Divider margin={2} />

            <Input
                label="Address two"
                name={`${type}.addressTwo`}
                value={values[type].addressTwo}
                onChange={handleChange}
            />
            {typeTouched && typeErrored && touched[type].addressTwo && errors[type].addressTwo && (
                <Fragment>
                    <Divider margin={2} />
                    <Alert message={errors[type].addressTwo} type="error" />
                </Fragment>
            )}
        </Fragment>
    );
};

export default Address;
