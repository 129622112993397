import React from 'react';

import styles from './styles.module.scss';

const Label = ({
    text = '',
    hint = false,
    required = false,
    className = '',
    onClick,
    ...props
}) => {
    return (
        <label
            className={`${styles.label} ${required ? styles.labelRequired : ''} ${
                onClick ? styles.labelHoverable : ''
            } ${className}`}
            {...props}
        >
            {text}
            {hint && <span>{hint}</span>}
        </label>
    );
};

export default Label;
